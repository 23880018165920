import { Logo } from "@winclap-platform/ui/components/logo";
import { BarChart3, FileLineChart } from "lucide-react";
import CompanySwitcher from "../auth/company-switcher";
import UserButton from "../auth/user-button";
import Link from "next/link";
import { cloneElement } from "react";
import { usePathname } from "next/navigation";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@winclap-platform/ui/components/tooltip";

const SidebarItem = ({
  icon,
  href,
  tooltip,
}: {
  icon: JSX.Element;
  href: string;
  tooltip: string;
}) => {
  const pathname = usePathname();
  const isActive = pathname.includes(href);
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link href={href} className="relative">
          {cloneElement(icon, {
            className: `${isActive ? "bg-gray-700 rounded-none" : ""} size-10 rounded p-2 text-white w-[72px] hover:bg-gray-700`,
          })}
          {isActive && (
            <div className="bg-primary absolute right-0 top-0 h-full w-1.5 rounded-l" />
          )}
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right" align="start" className="ml-[-7px] mt-[3px]">
        <p>{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  );
};

export const Sidebar = () => {
  return (
    <header className="fixed left-0 top-0 z-20 m-4 flex h-[calc(100dvh-2rem)] w-[72px] flex-col items-center justify-between rounded bg-black p-4">
      <div className="flex w-full flex-col items-center gap-4">
        <Logo color="white" height={48} />
        <div className="h-px w-full bg-white" />
        <CompanySwitcher />
        <div className="h-px w-full bg-white" />
        <SidebarItem
          icon={<FileLineChart />}
          href="/reporting"
          tooltip="Reporting module"
        />
        <SidebarItem
          icon={<BarChart3 />}
          href="/monitoring/pacing"
          tooltip="Monitoring module"
        />
      </div>
      <div className="flex w-full flex-col items-center gap-4">
        <div className="h-px w-full bg-white" />
        <UserButton />
      </div>
    </header>
  );
};
